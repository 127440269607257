.popover-content{
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  border: none;

  border-radius: 5px;

  .button-print{
    display: flex;
    align-items: center;

    background: transparent;
    color: white;
    border: none;
    font-weight: 700;
    gap: 0.5rem;
    font-size: 1rem;
  }
}
