.navigation-wrapper {
    position: relative;   
}

.keen-slider__slide {
    display: flex !important;
}

.dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}

.dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
}

.dot:focus {
    outline: none;
}

.dot.active {
    background: #000;
}

// @media (max-width: 1100px) {
//     .arrow {
//         display: none;
//     }
// }