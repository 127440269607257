.custom-modal-content {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
}

.custom-modal-header {
    padding: 0;
    border: none;
    width: 110%;
}


.close-modal {
    color: white;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

