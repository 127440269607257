@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

body,
html {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  overflow-x: hidden;
  @media (min-width: 1400px) {
    font-size: 1rem;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

:root {
  --primary-color: #5128E2;
  --primary-color-light: #c9bcf6;
  --contrast-color: #E7F0FF;
  

  --white: #fff;
  --black: #0a0a0a;
  --border-color: rgba(108, 123, 138, .08);
}

#root {
  min-height: 100vh;
}

p {
  color: rgba(18, 18, 18, 0.5);
}

.keen-slider__slide {
  display: flex;
  align-items: stretch;
}

h2 {
  span {
    color: rgba(51, 226, 180, 1);
    font-weight: 400;
  }
}

a,
button {
  -moz-transition: 0.32s ease;
  -webkit-transition: 0.32s ease;
  transition: 0.32s ease;
}

a {
  text-decoration: none;
}

.cover {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1657534205438-8fba10a56985?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80');

  &-sm {
    height: 9.689rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &::before {
      background-color: #ffffff;
      bottom: -0.1rem;
      border-radius: 0.469rem 0.469rem 0 0;
      content: '';
      height: 0.938rem;
      position: absolute;
      width: 100%;
    }
  }
}

.profile-image {
  margin-top: 9.5005rem;
  margin-bottom: 0.75rem;
}

.page-inner {
  min-height: 100vh;
  &-wrap {
    min-height: 100vh;
  }
  &-sidebar {
    background-color: rgba(231, 240, 255, 1);
  }
}

.message-details {
  font-size: 0.75rem;
}

.education-item {
  display: flex;
  margin-bottom: 1.25rem;
}

.text-primary-color {
  color: var(--primary-color);
}

.text-attention-size {
  font-size: 1.25rem;
}