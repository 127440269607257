@media print {
  .LayoutIP {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 2rem;  
  }

  .LayoutOfSafari {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .content-of-safari {
    margin-left: 300px; 
  }

  @page {
    margin: 32px 0; 
  }
}
